import React from 'react';
import moment from 'moment-timezone';
import { Button, Stack } from '@mui/material';
import { ModalChildProps } from '@components/AppModal';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledDatePicker } from '@components/inputs/ControlledDatePicker';
import { ControlledToggleButton } from '@components/inputs/ControlledToggleButton';
import { IAllocation } from '@features/allocations/allocation.interface';
import { useSaveAllocationForm } from './use-save-allocation-form';
import { ISODateString } from '@utils/dates/iso-string.type';

type Props = {
  allocation: IAllocation | void;
  orderId: number;
  endDate: ISODateString | null;
} & ModalChildProps;

export const SaveAllocationForm: React.FC<Props> = ({ allocation, orderId, onClose, endDate }) => {
  const { submitHandler, isSaveEnabled, isLabelsLoading, buttonText, control, watch, tutorOptions, supportOptions } =
    useSaveAllocationForm(allocation, orderId, onClose);

  const supportType = watch('nmhSupportType');
  const tutorForSelectedSupportOptions = tutorOptions.filter((option) => option.nmhSupportTypes.includes(supportType));

  const endDateMoment = moment(endDate);

  return (
    <form onSubmit={submitHandler}>
      <Stack direction="column" spacing={2}>
        <ControlledAutocomplete
          control={control}
          name="nmhSupportType"
          label="Type"
          options={supportOptions}
          isLoading={isLabelsLoading}
          required
        />
        <ControlledTextField
          control={control}
          name="hourlyRate"
          label="Hourly Rate"
          inputProps={{ inputMode: 'decimal' }}
          required
        />
        <ControlledTextField
          control={control}
          name="allocated"
          label="Hours Allocated"
          inputProps={{ inputMode: 'decimal' }}
          required
        />
        <ControlledDatePicker
          control={control}
          name="endDate"
          label="End Date"
          disablePast
          maxDate={endDateMoment}
          required
        />
        <ControlledAutocomplete
          control={control}
          name="tutorId"
          label="Tutor"
          options={tutorForSelectedSupportOptions}
          isLoading={isLabelsLoading}
          noOptionsText="First select Support Type"
        />
        <ControlledToggleButton
          control={control}
          name="isValidated"
          getLabel={(value: boolean): string => (value ? 'Validated' : 'Not Validated')}
        />
        <Button variant="contained" size="large" type="submit" disabled={!isSaveEnabled}>
          {buttonText}
        </Button>
      </Stack>
    </form>
  );
};
